import moment from 'moment'
import isEmpty from 'lodash.isempty'
import maxBy from 'lodash.maxby'
import minBy from 'lodash.minby'

import { TIMETABLE_OPTIONS, DAYS_OF_WEEK, MOMENT_DAYS_SHIFT } from './constants'

export const createTaskOptions = ({
  workout,
  progress_photo,
  update_measurements,
  group_training,
  step_target,
  weigh_in,
  check_in,
  id,
  ...rest
}) => {
  const options = []
  const taskData = {
    progress_photo,
    update_measurements,
    group_training,
    step_target,
    check_in,
    weigh_in,
  }
  if (workout) {
    options.push({
      label: workout.name,
      value: workout.id,
      id,
      ...rest,
    })
  }
  Object.entries(taskData).forEach(([taskName, taskValue]) => {
    if (taskValue) {
      options.push({
        label: TIMETABLE_OPTIONS[taskName],
        value: taskName,
        id,
        ...rest,
      })
    }
  })

  return options
}

export const createGoalChartData = (goal, loaded, measurement) => {
  if (loaded) {
    const sortedMeasure = measurement
      .filter(({ measure: { body_part } }) => body_part === 'Weight')
      .sort(({ date: dateA }, { date: dateB }) =>
        moment(dateA).diff(moment(dateB))
      )

    const matchStartDate = sortedMeasure.find(
      ({ date }) =>
        moment(date).format('L') === moment(goal.start_date).format('L')
    )
    const matchEndDate = sortedMeasure.find(
      ({ date }) =>
        moment(date).format('L') === moment(goal.goal_date).format('L')
    )

    const measureData = sortedMeasure
      .map(({ measure: { value, measure_type }, date, id }) => ({
        measure: Number(value),
        goal: null,
        name: moment(date).format('DD.MM.YYYY'),
        unit: measure_type,
        rawDate: moment(date).format('L'),
        type: 'Measurements',
        id,
      }))
      .filter(({ id }) => ![matchStartDate?.id, matchEndDate?.id].includes(id))

    const goalData = isEmpty(goal)
      ? []
      : [
          {
            goal: Number(goal.start_weight),
            measure: Number(matchStartDate?.measure.value) || null,
            name: moment(goal.start_date).format('DD.MM.YYYY'),
            unit: measureData?.[0]?.unit || '(lbs)',
            rawDate: moment(goal.start_date).format('L'),
            type: 'Goal',
          },
          {
            goal: Number(goal.goal_weight),
            measure: Number(matchEndDate?.measure.value) || null,
            name: moment(goal.goal_date).format('DD.MM.YYYY'),
            unit: measureData?.[0]?.unit || '(lbs)',
            rawDate: moment(goal.goal_date).format('L'),
            type: 'Goal',
          },
        ].filter(({ goal: goalValue }) => !!goalValue)

    return [
      ...goalData,
      ...measureData,
    ].sort(({ rawDate: dateA }, { rawDate: dateB }) =>
      moment(dateA).diff(moment(dateB))
    )
  }
  return []
}

export const getCurrentWeight = measurement => {
  const lastMeasurements = measurement
    .filter(({ measure: { body_part } }) => body_part === 'Weight')
    .sort(({ date: dateA }, { date: dateB }) =>
      moment(dateA).diff(moment(dateB))
    )
    .map(({ measure: { value } }) => Number(value))
    .pop()

  return lastMeasurements || 'No measurements yet'
}

export const getMinMaxStringFromArray = (array, field) => {
  const objectArray = array?.filter(
    el => typeof el === 'object' && !Array.isArray(el)
  )
  if (objectArray?.length > 1) {
    const { [field]: max } = maxBy(objectArray, ({ [field]: f }) =>
      Math.max(...String(f).split('-'))
    )
    const { [field]: min } = minBy(objectArray, ({ [field]: f }) =>
      Math.min(...String(f).split('-'))
    )
    if (max === min) return max
    const splitedMax = String(max).split('-')
    const splitedMin = String(min).split('-')
    return `${splitedMin[0]} - ${
      splitedMax.length > 1 ? splitedMax[1] : splitedMax[0]
    }`
  }
  return objectArray?.[0]?.[field]
}

export const getWeekRange = (startDate, endDate, weekNumber) => {
  const startDayOfWeek = MOMENT_DAYS_SHIFT[moment(startDate).day()]
  const plannedEndDate = moment(startDate).add(
    weekNumber * 7 - startDayOfWeek - 1,
    'days'
  )
  const plannedStartDate = moment(startDate).add(
    (weekNumber - 1) * 7 - startDayOfWeek,
    'days'
  )
  const minEndDate = moment.min([plannedEndDate, moment(endDate)])
  const maxStartDate = moment.max([plannedStartDate, moment(startDate)])

  return { start: maxStartDate, end: minEndDate }
}

export const getDayByStartDate = (startDate, weekNumber, dayName) => {
  const dayNumber = DAYS_OF_WEEK[dayName]

  return moment(startDate)
    .startOf('isoWeek')
    .add(weekNumber - 1, 'week')
    .add(dayNumber, 'days')
}

const mapKeyToEmptyArrs = (keys, startIdx, endIdx, startDate, weekNumber) => {
  const result = {}
  for (let i = startIdx; i <= endIdx; i += 1) {
    const date = getDayByStartDate(startDate, weekNumber, keys[i]).format('L')
    result[date] = []
  }
  return result
}

export const generateArrayOnDateRange = (startDate, endDate) => {
  const rangeArr = []
  let currDate = moment(startDate)
  while (currDate.diff(moment(endDate)) <= 0) {
    const endOfWeek = moment.min([
      moment(currDate).startOf('isoWeek').subtract(1, 'day').add(1, 'week'),
      moment(endDate),
    ])
    const currDay = Math.max(
      DAYS_OF_WEEK.Monday,
      DAYS_OF_WEEK[currDate.format('dddd')]
    )
    const endDay = Math.min(
      DAYS_OF_WEEK.Sunday,
      DAYS_OF_WEEK[endOfWeek.format('dddd')]
    )
    rangeArr.push(
      mapKeyToEmptyArrs(
        Object.keys(DAYS_OF_WEEK),
        currDay,
        endDay,
        startDate,
        rangeArr.length + 1
      )
    )
    currDate = endOfWeek.add('1', 'day')
  }
  return rangeArr
}

export const transformOption = value =>
  !Object.keys(TIMETABLE_OPTIONS).includes(value) ? 'workout' : value

export function deepMapKeys(originalObject, callback) {
  if (typeof originalObject !== 'object') {
    return originalObject
  }

  return Object.keys(originalObject || {}).reduce((newObject, key) => {
    const newKey = callback(key)
    const originalValue = originalObject[key]
    let newValue = originalValue
    if (Array.isArray(originalValue)) {
      newValue = originalValue.map(item => deepMapKeys(item, callback))
    } else if (typeof originalValue === 'object') {
      newValue = deepMapKeys(originalValue, callback)
    }
    return {
      ...newObject,
      [newKey]: newValue,
    }
  }, {})
}

export const setsDataMerge = (data, storage) =>
  data?.map((sets, index) => {
    if (Array.isArray(sets)) {
      return sets.map(s => {
        const initSet = storage?.sets?.[index]?.find(iSet => iSet.id === s.id)

        if (initSet) {
          return {
            ...s,
            weight: initSet.weight,
            current_reps: initSet.current_reps,
          }
        }
        return s
      })
    }
    return {
      ...sets,
      weight: storage?.sets?.[index].weight,
      current_reps: storage?.sets?.[index].current_reps,
    }
  })

export const sortByField = (data, sortingField) =>
  data.sort((a, b) => {
    const nameA = a[sortingField].toLowerCase()
    const nameB = b[sortingField].toLowerCase()

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
