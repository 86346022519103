import { TRAINER, CLIENT } from '~/utilities/constants'

import { createApiAction, throwError, createAction } from '~/state/utils'
import Impact from '~/services/Impact'
import { deserializer } from '~/services/Deserializer'
import { updateClientNotification } from '../user/actions'

export const FETCH_GOAL = createApiAction('goal / FETCH_GOAL')
export const ADD_GOAL = 'goal / ADD_GOAL'
export const SET_CLIENT_NOTIFICATION = 'goal / SET_CLIENT_NOTIFICATION'

export const addGoalAction = createAction(FETCH_GOAL.SUCCESS)
export const setClientNotification = createAction(SET_CLIENT_NOTIFICATION)

export const getGoal = (id, role, clientCommentsCallback) =>
  Impact.thunk(FETCH_GOAL, async () => {
    const { goal, client_comments, trainer_notification, client_notification } =
      role === TRAINER ? await Impact.getGoal(id) : await Impact.getClientGoal()
    const dataDeserializer = deserializer()

    const data = await dataDeserializer(goal)

    if (clientCommentsCallback) {
      const clientComments = await dataDeserializer(client_comments)
      clientCommentsCallback(clientComments)
    }

    return { data: { ...data, trainer_notification, client_notification } }
  })

export const setCheckInNotification = (
  id,
  notification,
  role
) => async dispatch => {
  const actionCreator =
    role === CLIENT
      ? Impact.updateClientCheckInComment
      : Impact.updateTrainerCheckInComment

  const {
    data: { client_notification, trainer_notification },
  } = await actionCreator({ id, client_comment: notification })
  const payload =
    role === CLIENT ? { client_notification } : { trainer_notification }

  dispatch(setClientNotification(payload))

  if (role === CLIENT) {
    dispatch(updateClientNotification())
  }
}

export const createGoal = (values, role) => async dispatch => {
  try {
    const {
      data: { goal, message },
    } =
      role === TRAINER
        ? await Impact.createGoal(values)
        : await Impact.createGoalClient(values)

    const data = await deserializer()(goal)

    dispatch(addGoalAction(data, message))
  } catch (err) {
    throwError(err)
  }
}
