import { Deserializer } from 'jsonapi-serializer'

import Impact from '~/services/Impact'
import { createAction, createApiAction, throwError } from '~/state/utils'
import { getDayByStartDate } from '~/utilities/helpers'

import { DeepDeserializer } from '~/services/Deserializer'

export const FETCH = createApiAction('client_dashboard / FETCH_DATA')

export const getClientDashboard = () =>
  Impact.thunk(
    FETCH,
    async () => {
      const {
        goal,
        measures,
        client_programs,
        checked_in,
      } = await Impact.getClientDashboard()
      const deserializer = new Deserializer({
        keyForAttribute: 'snake_case',
      })
      const goalData = await deserializer.deserialize(goal)
      const measuresData = await deserializer.deserialize(measures)

      const promises = client_programs.map(deserializer.deserialize)
      const programData = await Promise.all(promises)

      const programDataWithId = programData.map((programTasks, idx) =>
        programTasks.map(dayTask => ({
          ...dayTask,
          programId: client_programs[idx].id,
          date: getDayByStartDate(
            client_programs[idx].start_date,
            dayTask.week_number,
            dayTask.day_name
          ).format('L'),
        }))
      )

      return {
        data: {
          goal: goalData,
          measures: measuresData,
          programs: programDataWithId,
          checkedIn: checked_in,
        },
      }
    },
    {}
  )

export const updateStepsCount = answer => async dispatch => {
  try {
    const {
      data: { program },
    } = await Impact.updateStepsCount(answer)
    const payload = await new DeepDeserializer().deserialize(program)
    await dispatch(createAction(FETCH.SUCCESS)(payload))
  } catch (err) {
    throwError(err)
  }
}
