import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import { FETCH, GET_STEPS_COUNT } from './actions'

const initialState = {
  data: [],
  stepsCount: [],
  loading: false,
  loaded: false,
}

const clientHistoryReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH),
  [GET_STEPS_COUNT]: (state, { payload }) => ({
    ...state,
    stepsCount: payload,
  }),
})

export default clientHistoryReducer
