import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  AppBar as MuiAppBar,
  Toolbar,
  Container,
  makeStyles,
} from '@material-ui/core'

import Navigation from '../Navigation'
import LogoSvg from '~/views/components/LogoSvg/LogoSvg'
import Link from '~/views/shared/Link'

const useStyles = makeStyles(theme => ({
  logoLink: {
    flexGrow: 1,
    height: 20,
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}))

const links = [
  {
    name: 'Dashboard',
    path: 'dashboard',
  },
  {
    name: 'Clients',
    path: 'clients',
  },
  {
    name: 'Programs',
    path: 'programs',
  },
  {
    name: 'Workouts',
    path: 'workouts',
  },
  {
    name: 'Nutrition',
    path: 'nutrition',
  },
  {
    name: 'Profile',
    path: 'profile',
  },
]

const Header = () => {
  const classes = useStyles()
  const isTablet = useMediaQuery('(min-width:768px)')

  return (
    <MuiAppBar position="fixed">
      <Container>
        <Toolbar disableGutters>
          <Link className={classes.logoLink} to="/">
            <LogoSvg />
          </Link>
          {isTablet && <Navigation links={links} />}
        </Toolbar>
      </Container>
    </MuiAppBar>
  )
}

export default Header
