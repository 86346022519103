import React from 'react'
import loadable from '@loadable/component'

import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import HomeIcon from '@material-ui/icons/Home'
import RestaurantOutlinedIcon from '@material-ui/icons/RestaurantOutlined'
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined'
import PersonIcon from '@material-ui/icons/Person'
import { Box, Hidden } from '@material-ui/core'

const BottomMenu = loadable(() =>
  import('~/views/components/BottomMenu' /* webpackChunkName: "bottom-menu" */)
)

const bottomLinks = [
  {
    Icon: HomeIcon,
    path: '/client/dashboard',
  },
  {
    Icon: FitnessCenterIcon,
    path: '/client/workouts',
  },
  {
    Icon: RestaurantOutlinedIcon,
    path: '/client/nutrition',
  },
  {
    Icon: EqualizerOutlinedIcon,
    path: '/client/measurements',
    indicator: true,
  },
]

const sidebarLinks = [
  {
    Icon: PersonIcon,
    path: '/client/profile',
    name: 'Profile',
  },
]

const RoutesWrapper = ({ children }) => (
  <Box display="flex" flexDirection="column" minHeight="100vh">
    {children}
    <Hidden mdUp>
      <BottomMenu bottomLinks={bottomLinks} sidebarLinks={sidebarLinks} />
    </Hidden>
  </Box>
)

export default RoutesWrapper
