import { createSelector } from 'reselect'

const workoutDataSelector = state => state.workout.data
const workoutFoldersDataSelector = state => state.folders.workout

export const getWorkoutFoldersOptions = createSelector(
  workoutFoldersDataSelector,
  ({ data, items, currentFolder }) => ({
    data: [
      ...data?.map(({ name, id, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
      })),
      ...items?.map(({ name, id, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
      })),
    ],
    currentFolder,
  })
)

export const getWorkoutOptions = createSelector(workoutDataSelector, data => {
  if (!Array.isArray(data)) return []
  return data.map(({ id, name }) => ({
    label: name,
    value: id,
    workout: 'Workout',
  }))
})

export const getWorkoutExerciseProgress = createSelector(
  workoutDataSelector,
  ({ workoutExercise = [] }) =>
    workoutExercise.map(({ id, progress }) => ({
      [`progress_${id}`]: progress,
    }))
)
