import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import { FETCH_PROGRESS_PHOTO, ADD_IMAGE, REMOVE_IMAGE } from './actions'

const initialState = {
  data: [],
  loading: false,
  loaded: false,
}

const progressPhotoReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH_PROGRESS_PHOTO),
  [ADD_IMAGE]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload],
  }),
  [REMOVE_IMAGE]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(({ id }) => id !== payload),
  }),
})

export default progressPhotoReducer
