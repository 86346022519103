import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import {
  FETCH_NUTRITION,
  CREATE_NUTRITION,
  FETCH_NUTRITION_BY_ID,
  UPDATE_NUTRITION,
} from './actions'

const initialState = {
  data: [],
  loading: false,
  loaded: false,
}

const nutritionReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH_NUTRITION),
  ...fetchAndSetApiAction(FETCH_NUTRITION_BY_ID),
  [CREATE_NUTRITION]: (state, { payload }) => ({
    ...state,
    data: payload,
  }),
  [UPDATE_NUTRITION]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  }),
})

export default nutritionReducer
