import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import { FETCH_MEASUREMENTS, ADD_MEASURE, REMOVE_MEASURE } from './actions'

const initialState = {
  data: [],
  loading: false,
  loaded: false,
}

const measurementReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH_MEASUREMENTS),
  [ADD_MEASURE]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    ),
  }),
  [REMOVE_MEASURE]: (state, { payload }) => ({
    ...state,
    data: state.data.filter(measure => measure.id !== payload),
  }),
})

export default measurementReducer
