import Api from '../state/utils/api'
import { API_URL } from '../utilities/constants'

class Impact extends Api {
  /**
   * User login
   */
  login = (user, data) => this.post(`/api/v1/${user}/sessions`, { data })

  /**
   * User forgot password
   */
  forgotPassword = data => this.post(`/api/v1/user/passwords/emails`, { data })

  /**
   * User reset password
   */
  resetPassword = data => this.post(`/api/v1/user/passwords`, { data })

  /**
   * Get all trainer's  clients
   */
  trainerGetClients = () =>
    this.get('/api/v1/trainers/clients/clients').then(({ data }) => data)

  /**
   * Refresh token
   */
  refreshToken = user => this.post(`/api/v1/${user}s/tokens`)

  /**
   * Create workout
   */
  createWorkout = data => this.post('/api/v1/trainers/workouts', { data })

  /**
   * Delete workout
   */
  deleteWorkout = id => this.del(`/api/v1/trainers/workouts/${id}`)

  /**
   * Delete program
   */
  deleteProgram = id => this.del(`/api/v1/trainers/programs/${id}`)

  /**
   * Delete folder
   */
  deleteFolder = id => this.del(`/api/v1/trainers/folders/${id}`)

  /**
   * Update folder
   */
  updateFolder = (id, data) =>
    this.put(`/api/v1/trainers/folders/${id}`, { data })

  /**
   * Copy folder
   */
  copyFolder = data => this.post(`/api/v1/trainers/copy/folders`, { data })

  /*
   * Create workout
   */
  createProgram = data => this.post('/api/v1/trainers/programs', { data })

  /*
   * Create folder
   */
  createFolder = data => this.post('/api/v1/trainers/folders', { data })

  /**
   * Get all trainer's  clients
   */
  getAllExercises = () => this.get('/api/v1/trainers/exercises')

  /**
   * Update trainer
   */
  updateTrainer = (data, id) =>
    this.put(`/api/v1/trainers/trainers/${id}`, { data })

  /**
   * Update client
   */
  updateClient = (data, id) =>
    this.put(`/api/v1/clients/clients/${id}`, { data })

  /**
   * Update trainer
   */
  getClientPrograms = id =>
    this.get(`/api/v1/trainers/clients/programs`, {
      params: { client_id: id },
    }).then(({ data }) => data)

  /**
   * Get client by id
   */
  getClientById = id =>
    this.get(`/api/v1/trainers/clients/clients/${id}`).then(({ data }) => data)

  /**
   * Get programs by client id
   */
  getProgramsById = id =>
    this.get(`/api/v1/trainers/clients/programs`, {
      params: { client_id: id },
    }).then(({ data }) => data)

  /**
   * Direct upload of one file
   */
  directUploadFile = file => this.directUpload(file)

  /**
   * Direct upload of all files
   */
  directUploadMultiple = files =>
    Promise.all(files.map(file => this.directUpload(file)))

  /**
   * Get workouts for current trainer
   */
  getAllWorkouts = params =>
    this.get('/api/v1/trainers/workouts', { params }).then(({ data }) => data)

  /**
   * Get workouts for all trainers
   */
  getAllTrainersWorkouts = () =>
    this.get('/api/v1/trainers/workouts?all=true').then(({ data }) => data)

  /**
   * Get one workout
   */
  getWorkout = id =>
    this.get(`/api/v1/trainers/workouts/${id}`).then(({ data }) => data)

  /**
   * Clone workout
   */
  cloneWorkout = data => this.post(`/api/v1/trainers/copy/workouts`, { data })

  /**
   * Clone nutrition
   */
  cloneNutrition = data =>
    this.post(`/api/v1/trainers/copy/nutritions`, { data })

  /**
   * Clone program
   */
  cloneProgram = data => this.post(`/api/v1/trainers/copy/programs`, { data })

  /*
   * Get all nutrition
   */
  getAllNutrition = params =>
    this.get('/api/v1/trainers/nutritions', { params }).then(({ data }) => data)

  /**
   * Get all programs
   */
  getAllPrograms = params =>
    this.get('/api/v1/trainers/programs', { params }).then(({ data }) => data)

  /**
   * Get folders
   */
  getFoldersByType = folder_type =>
    this.get('/api/v1/trainers/folders', { params: { folder_type } }).then(
      ({ data }) => data
    )

  /**
   * Get folder by id
   */
  getFolderById = id =>
    this.get(`/api/v1/trainers/folders/${id}`).then(({ data }) => data)

  /**
   * Get folders by search
   */
  getFoldersBySearch = (folder_type, search_value) =>
    this.get('/api/v1/trainers/folders', {
      params: { folder_type, search_value },
    }).then(({ data }) => data)

  /**
   * Show program by id
   */
  getProgramById = id =>
    this.get(`/api/v1/trainers/programs/${id}`).then(({ data }) => data)

  /**
   * Get programs by search
   */
  getProgramsBySearch = search_value =>
    this.get('/api/v1/trainers/programs', {
      params: { search_value },
    }).then(({ data }) => data)

  /**
   * Get nutrition by search
   */
  getNutritionBySearch = search_value =>
    this.get('/api/v1/trainers/nutritions', {
      params: { search_value },
    }).then(({ data }) => data)

  /**
   * Get workouts by search
   */
  getWorkoutsBySearch = search_value =>
    this.get('/api/v1/trainers/workouts', {
      params: { search_value },
    }).then(({ data }) => data)

  /**
   * Show task by id
   */
  getTaskById = (id, client_id) =>
    this.get(`/api/v1/trainers/tasks`, {
      params: { program_id: id, client_id },
    }).then(({ data }) => data)

  /**
   * Create workout exercise
   */
  createWorkoutExercise = data =>
    this.post('/api/v1/trainers/workout_exercises', { data })

  /**
   * Update workout exercise
   */
  updateWorkoutExercise = (id, data) =>
    this.put(`/api/v1/trainers/workout_exercises/${id}`, { data })

  /**
   * Update workout
   */
  updateWorkout = (id, data) =>
    this.put(`/api/v1/trainers/workouts/${id}`, { data })

  /**
   * Delete workout exercise
   */
  deleteWorkoutExercise = id =>
    this.del(`/api/v1/trainers/workout_exercises/${id}`)

  /*
   * Create tasks
   */
  createTasks = data => this.post('/api/v1/trainers/tasks', { data })

  /**
   * Delete tasks
   */
  deleteTasks = data => this.del(`/api/v1/trainers/tasks/1`, { data })

  /**
   * Assign client to a program
   */
  createClientProgram = data =>
    this.post('/api/v1/trainers/clients/programs', { data })

  /**
   * Unassign client to a program
   */
  deleteClientProgram = (id, data) =>
    this.del(`/api/v1/trainers/clients/programs/${id}`, { data })

  /**
   * Update program
   */
  updateProgram = (id, program) =>
    this.put(`/api/v1/trainers/programs/${id}`, { data: { program } })

  /**
   * Get all progress photos
   */
  getAllProgressPhoto = id =>
    this.get(`/api/v1/trainers/clients/progress_photos`, {
      params: { client_id: id },
    }).then(({ data }) => data)

  /**
   * Delete progress photos
   */
  deleteProgressPhoto = (id, clientId) =>
    this.del(`/api/v1/trainers/clients/progress_photos/${id}`, {
      params: { client_id: clientId },
    }).then(({ data }) => data)

  /**
   * Load progress photo
   */
  createProgressPhoto = data =>
    this.post('/api/v1/trainers/clients/progress_photos', { data })

  /**
   * Get all client measurements
   */
  getAllMeasurements = (id, isStepCount = undefined) =>
    this.get('/api/v1/trainers/clients/measures', {
      params: { client_id: id, steps_count: isStepCount },
    }).then(({ data }) => data)

  /**
   * Create measurement
   */
  createMeasurement = data =>
    this.post('/api/v1/trainers/clients/measures', { data })

  /**
   * Remove measurement
   */
  removeMeasurement = (measureId, clientId) =>
    this.del(`/api/v1/trainers/clients/measures/${measureId}`, {
      params: { client_id: clientId },
    })

  /*
   * Create nutrition
   */
  createNutrition = data => this.post('/api/v1/trainers/nutritions', { data })

  /*
   * Delete nutrition
   */
  deleteNutrition = id => this.del(`/api/v1/trainers/nutritions/${id}`)

  /**
   * Get nutrition by id
   */
  getNutritionById = id =>
    this.get(`/api/v1/trainers/nutritions/${id}`).then(({ data }) => data)

  getClientNutritionByProgramId = program_id =>
    this.get(
      `/api/v1/trainers/clients/nutritions?client_program_id=${program_id}`
    ).then(({ data }) => data)

  /**
   * Update nutrition
   */
  updateNutrition = (id, data) =>
    this.put(`/api/v1/trainers/nutritions/${id}`, { data })

  /**
   * Get trainer dashboard
   */
  getTrainerDashboard = () =>
    this.get('/api/v1/trainers/dashboard').then(({ data }) => data)

  /*
   * Get goal
   */
  getGoal = id =>
    this.get(`/api/v1/trainers/clients/goals`, {
      params: { client_id: id },
    }).then(({ data }) => data)

  /**
   * Create goal
   */
  createGoal = data => this.post(`/api/v1/trainers/clients/goals`, { data })

  /**
   * Get client dashboard
   */
  getClientDashboard = () =>
    this.get('/api/v1/clients/dashboards').then(({ data }) => data)

  /**
   * Get client nutritions
   */
  getClientProgram = () =>
    this.get('/api/v1/clients/programs').then(({ data }) => data)

  /*
   * Get goal client side
   */
  getClientGoal = () =>
    this.get(`/api/v1/clients/goals`).then(({ data }) => data)

  /**
   * Create goal client side
   */
  createGoalClient = data => this.post(`/api/v1/clients/goals`, { data })

  /**
   * Get all client measurements client side
   */
  getAllClientMeasurements = (isStepCount = undefined) =>
    this.get('/api/v1/clients/measures', {
      params: { steps_count: isStepCount },
    }).then(({ data }) => data)

  /**
   * Get all progress photos client side
   */
  getAllProgressPhotoClient = () =>
    this.get('/api/v1/clients/progress_photos').then(({ data }) => data)

  /**
   * Load progress photo client side
   */
  createProgressPhotoClient = data =>
    this.post('/api/v1/clients/progress_photos', { data })

  /**
   * Delete progress photos client side
   */
  deleteProgressPhotoClient = id =>
    this.del(`/api/v1/clients/progress_photos/${id}`).then(({ data }) => data)

  /**
   * Create measurement client side
   */
  createMeasurementClient = data =>
    this.post('/api/v1/clients/measures', { data })

  /**
   * Remove measurement client side
   */
  removeMeasurementClient = measureId =>
    this.del(`/api/v1/clients/measures/${measureId}`)

  /**
   * Get client history client side
   */
  getClientHistoryClient = id =>
    this.get('/api/v1/clients/history', { params: { client_id: id } }).then(
      ({ data }) => data
    )

  /**
   * Get workouts for all trainers
   */
  getAllTrainersWorkoutsClient = () =>
    this.get('/api/v1/clients/workouts?all=true').then(({ data }) => data)

  /**
   * Get client nutritions
   */
  getClientNutrition = () =>
    this.get(`/api/v1/clients/nutritions`).then(({ data }) => data)

  /**
   * Get client nutritions
   */
  getTrainerClientNutrition = id =>
    this.get(`/api/v1/trainers/clients/nutritions?client_id=${id}`).then(
      ({ data }) => data
    )

  /**
   * Update client nutritions
   */
  updateClientNutrition = (id, data) =>
    this.put(`/api/v1/clients/programs/${id}`, { data })

  /**
   * Get client workout
   */
  getClientWorkout = (id, program_id) =>
    this.get(`/api/v1/clients/sets/${id}`, { params: { program_id } }).then(
      ({ data }) => data
    )

  /**
   * Send set
   */
  sendSet = (id, data) => this.put(`/api/v1/clients/sets/${id}`, { data })

  /**
   * Get client sets
   */
  getClientsSet = (exerciseId, client_id, program_id) =>
    this.get(`/api/v1/trainers/clients/sets/${exerciseId}`, {
      params: { client_id, program_id },
    }).then(({ data }) => data)

  /**
   * Send client set
   */
  sendClientSet = (id, data) =>
    this.put(`/api/v1/trainers/clients/sets/${id}`, { data })

  /**
   * Get client history
   */
  getClientHistory = id =>
    this.get(`/api/v1/trainers/clients/history`, {
      params: { client_id: id },
    }).then(({ data }) => data)

  /**
   * Update client steps count
   */
  updateStepsCount = measure =>
    this.post('/api/v1/clients/measures', { data: { measure } })

  /**
   * Get all client check-in comments
   */
  getClientCheckInComments = () => this.get('/api/v1/clients/client_comments')

  /**
   * Create client check-in comments
   */
  createClientCheckInComment = ({ client_comment }) =>
    this.post('/api/v1/clients/client_comments', { data: { client_comment } })

  /**
   * Create client check-in comments
   */
  deleteClientCheckInComment = id =>
    this.del(`/api/v1/clients/client_comments/${id}`)

  /**
   * Update client check-in comment
   */
  updateClientCheckInComment = ({ id, client_comment }) =>
    this.put(`/api/v1/clients/client_comments/${id}`, {
      data: { client_comment },
    })

  /**
   * Update trainer check-in comment
   */
  updateTrainerCheckInComment = ({ id, client_comment }) =>
    this.put(`/api/v1/trainers/clients/client_comments/${id}`, {
      data: { client_comment },
    })

  /**
   * Update client notification
   */
  updateClientNotification = () => this.get(`/api/v1/clients/notifications`)
}

export default new Impact({
  baseURL: API_URL,
})
