import React from 'react'

const LogoSvg = () => (
  <svg
    width="100"
    height="15"
    viewBox="0 0 100 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 14.3886H3.75582V0.356018H0V14.3886Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7204 0.211453C11.7411 0.0670062 11.8236 0.0876407 11.9061 0.190817L18.6337 7.74373L25.2578 0.190817C25.3403 0.0876407 25.4229 0.0670062 25.4435 0.211453L26.2689 14.3885H22.575L22.2448 8.40406L18.7368 12.4694C18.6749 12.5313 18.613 12.5313 18.5511 12.4694L14.8985 8.38343L14.5683 14.3885H10.895L11.7204 0.211453Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.4636 8.17739C40.1559 8.17739 40.775 7.22777 40.775 5.86584C40.775 4.50391 40.1559 3.53405 38.4636 3.53405H37.0811V8.17739H38.4636ZM37.0811 14.3886H33.3254V0.356018H38.6495C42.4258 0.356018 44.5514 2.74991 44.5514 5.86584C44.5514 8.98197 42.4258 11.355 38.6495 11.355H37.0811V14.3886Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.932 7.12473L55.5288 10.3851H58.3763L56.932 7.12473ZM53.8159 14.3886H50.1426L56.9731 0.108335C57.0352 -0.0361118 57.159 -0.0361118 57.2207 0.108335L64.0307 14.3886H60.1513L59.6146 13.1917C59.6352 13.1917 54.3317 13.1917 54.3317 13.1917L53.8159 14.3886Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.9482 14.5743C73.0066 14.5743 69.6431 11.6026 69.6431 7.37234C69.6431 3.1419 72.986 0.190857 76.7418 0.190857C79.7752 0.190857 81.6326 1.47024 82.8088 3.20381L79.9817 5.12289C79.445 4.29748 78.5785 3.45143 76.8037 3.45143C74.74 3.45143 73.4812 5.16416 73.4812 7.37234C73.4812 9.58051 74.7815 11.3139 76.9277 11.3139C78.7436 11.3139 79.6309 10.4885 80.2294 9.37396L83.1185 11.2313C81.7357 13.5631 79.796 14.5743 76.9482 14.5743Z"
      fill="#FFFFFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.53 3.51342H88.8157V0.356018H100V3.51342H96.2857V14.3886H92.53V3.51342Z"
      fill="#FFFFFE"
    />
  </svg>
)

export default LogoSvg
