import { createSelector } from 'reselect'
import moment from 'moment'

import {
  getDayByStartDate,
  generateArrayOnDateRange,
} from '~/utilities/helpers'

const historyDataSelector = state => state.clientHistory.data
const workoutDataSelector = state => state.workout.data
const clientProgramDataSelector = state => state.clientPrograms.data
const historyStepsCountSelector = state => state.clientHistory.stepsCount

export const formClientHistory = createSelector(
  historyDataSelector,
  workoutDataSelector,
  clientProgramDataSelector,
  historyStepsCountSelector,
  (history, workoutData, programs, inputStepsCount) => {
    if (
      !Array.isArray(workoutData) ||
      history.length === 0 ||
      programs.length === 0
    ) {
      return { clientHistory: [] }
    }

    const programById = history.reduce(
      (
        acc,
        { followed_nutrition, id, start_date, end_date, task = [], steps }
      ) => {
        const tasks = task.map(
          ({ week_number, day_name, workout_id, program_id, id: taskId }) => ({
            week_number,
            day_name,
            workout_id,
            program_id,
            id: taskId,
            date: getDayByStartDate(start_date, week_number, day_name).format(
              'L'
            ),
          })
        )

        acc[id] = {
          followed_nutrition,
          start_date: moment(start_date).format('L'),
          end_date: moment(end_date).format('L'),
          day_name: moment(start_date).format('dddd'),
          tasks,
          steps,
        }
        return acc
      },
      {}
    )

    const workoutById = history.reduce((acc, { id, task = [] }) => {
      task.forEach(({ workout_id, set }) => {
        const sets = set
          .filter(({ progress }) => !!progress.real_data)
          .map(
            ({ workout_exercise_id, id: setId, progress: { real_data } }) => ({
              workout_exercise_id,
              id: setId,
              ...real_data,
            })
          )
        acc[`${id}_${workout_id}`] = sets
      })
      return acc
    }, {})

    const steps = inputStepsCount
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .reduce(
        (acc, el) => ({
          ...acc,
          ...(el.measure
            ? { [moment(el.date).format('L')]: el.measure.value }
            : {}),
        }),
        {}
      )

    const byWeek = Object.entries(programById).reduce(
      (acc, [id, { start_date, end_date, followed_nutrition, tasks }]) => {
        const endDate = moment.min([moment(), moment(end_date)])

        const timeTable = generateArrayOnDateRange(start_date, endDate).map(
          week =>
            Object.keys(week).reduce((currWeek, date) => {
              let nutrition = followed_nutrition?.[date] ?? 'Missed'
              if (nutrition === true) nutrition = 'Follow'
              else nutrition = 'Not Follow'

              if (
                nutrition !== true &&
                typeof followed_nutrition?.[date] === 'string'
              ) {
                nutrition = `Not Follow (${followed_nutrition?.[date]})`
              }

              let stepsCount = steps?.[date] ?? 'Missed'
              if (steps) {
                if (!(date in steps)) stepsCount = 'Missed'
              }

              const currTasks = tasks.filter(
                ({ date: taskDate }) => taskDate === date
              )

              const workout = currTasks.reduce((accum, { workout_id }) => {
                const prev = accum
                const currentWorkout = Array.isArray(workoutData)
                  ? workoutData.find(
                      ({ id: workoutId }) => +workoutId === workout_id
                    )
                  : workout_id

                const sets = workoutById[`${id}_${workout_id}`]
                  .filter(item => !!item[date])
                  .map(({ workout_exercise_id, ...item }) => {
                    const currentExercise = currentWorkout?.workout_exercises.find(
                      ({ id: exerId }) => +workout_exercise_id === +exerId
                    )
                    return {
                      ...item[date],
                      name: currentExercise?.name,
                    }
                  })
                  .sort((a, b) => a.position - b.position)

                prev[currentWorkout?.name] = sets
                return prev
              }, {})

              const previousValue = currWeek
              previousValue[date] = { nutrition, workout, stepsCount }
              return previousValue
            }, {})
        )

        acc[id] = timeTable
        return acc
      },
      {}
    )

    const clientHistory = Object.entries(byWeek)
      .map(([programId, week]) => {
        const currentProgram = programs.find(({ id }) => id === programId)
          ?.program

        return week.map((item, idx) => ({
          name: currentProgram?.name,
          days: Object.keys(item)
            .sort((a, b) => new Date(b) - new Date(a))
            .reduce((acc, key) => {
              acc[key] = item[key]
              return acc
            }, {}),
          weekNumber: idx + 1,
        }))
      })
      .flat()
      .sort(
        (weekA, weekB) =>
          moment(Object.keys(weekB.days)[0]).valueOf() -
          moment(Object.keys(weekA.days)[0]).valueOf()
      )

    return { clientHistory }
  }
)
