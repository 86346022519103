import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import {
  CREATE_WORKOUT,
  FETCH_WORKOUTS,
  GET_ONE,
  UPDATE_WORKOUT_EXERCISE,
  DELETE_WORKOUT_EXERCISES,
  ADD_WORKOUT_EXERCISE,
  UPDATE_WORKOUT,
} from './actions'

const initialState = {
  data: {
    workoutExercise: [
      { reps: 0, rest: 0, tempo: 0, start_weight: 0, exercise: {} },
    ],
    id: null,
    name: null,
    image: null,
  },
  loading: false,
  loaded: false,
}

const workoutReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH_WORKOUTS),
  ...fetchAndSetApiAction(GET_ONE),
  [UPDATE_WORKOUT]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      ...payload,
    },
  }),
  [CREATE_WORKOUT]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload],
  }),
  [ADD_WORKOUT_EXERCISE]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      workoutExercise: [...state.data.workoutExercise, ...payload],
    },
  }),
  [DELETE_WORKOUT_EXERCISES]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      workoutExercise: state.data.workoutExercise.filter(
        ({ id }) => id !== payload
      ),
    },
  }),
  [UPDATE_WORKOUT_EXERCISE]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      workoutExercise: [...payload],
    },
  }),
})

export default workoutReducer
