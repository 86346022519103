import { createSelector } from 'reselect'

const programsDataSelector = state => state.folders.program

export const getProgramOptions = createSelector(
  programsDataSelector,
  ({ data, items, currentFolder }) => ({
    data: [
      ...data.map(({ id, name, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
      })),
      ...items.map(({ id, name, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
      })),
    ],
    currentFolder,
  })
)
