import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'

import { DAYS_OF_WEEK } from '~/utilities/constants'
import { createTaskOptions } from '~/utilities/helpers'

const clientProgramsSelector = state => state.clientPrograms.programs
const clientDataProgramsSelector = state => state.clientPrograms.data
const clientPrograms = state => state.clientPrograms.data

export const clientProgramSelector = createSelector(
  clientProgramsSelector,
  programs =>
    memoize(id =>
      Object.entries(programs).reduce((accumulator, [programId, value]) => {
        const byWeek = accumulator
        if (Number(programId) === id) {
          value.forEach(({ day_name, week_number, ...rest }) => {
            if (!byWeek[week_number]) {
              byWeek[week_number] = {}
            }
            if (!byWeek[week_number][programId]) {
              byWeek[week_number][programId] = {}
              Object.keys(DAYS_OF_WEEK).forEach(dayName => {
                byWeek[week_number][programId][dayName] = []
              })
            }

            if (!byWeek[week_number][programId][day_name]) {
              byWeek[week_number][programId][day_name] = []
            }

            byWeek[week_number][programId][day_name].push(
              ...createTaskOptions({ ...rest, programId })
            )
          })
        }

        return byWeek
      }, {})
    )
)

export const getProgramInfo = createSelector(
  clientDataProgramsSelector,
  programs =>
    programs.map(({ start_date, end_date, id, program }) => ({
      start_date,
      end_date,
      ...program,
      id,
    }))
)
