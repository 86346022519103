import { Deserializer } from 'jsonapi-serializer'
import { toastr } from 'react-redux-toastr'
import history from '~/history'

import Impact from '~/services/Impact'

import { createApiAction, throwError } from '~/state/utils'
import { deserializer } from '../../../../services/Deserializer'

// Action constants
export const FETCH_CLIENTS = createApiAction('trainer / clients / FETCH_DATA')
export const FETCH_CLIENT_BY_ID = createApiAction(
  'trainer / clients / FETCH_CLIENT_BY_ID'
)
export const FETCH_SET = createApiAction(
  'trainer / client / sets /  FETCH_DATA'
)

export const getTrainerClients = () =>
  Impact.thunk(FETCH_CLIENTS, async () => {
    const { clients } = await Impact.trainerGetClients()

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(clients)

    return { data }
  })

export const loadClientById = async ({ id }) => {
  try {
    const { client } = await Impact.getClientById(id)

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(client)

    return data
  } catch (err) {
    await history.push('/trainer/clients')
    if (err.response.status === 404) {
      toastr.warning('Warning', "Can't find client")
    } else {
      toastr.error('Error', 'Oops something went wrong')
    }
    return Promise.reject(err)
  }
}

export const getClientsSet = (exerciseId, clientId, programId) =>
  Impact.thunk(FETCH_SET, async () => {
    try {
      const { set, workout } = await Impact.getClientsSet(
        exerciseId,
        clientId,
        programId
      )

      let setData = await new Deserializer({
        keyForAttribute: 'snake_case',
      }).deserialize(set)

      setData = setData.map(item => {
        if (Array.isArray(item.progress))
          return { ...item, progress: { default: item.progress } }
        return item
      })

      const workoutData = await deserializer()(workout)

      return { data: { set: setData, workout: workoutData } }
    } catch (err) {
      await history.push(`/trainer/client/${clientId}`)
      if (err.response.status === 404) {
        toastr.warning('Warning', "Can't find program")
      } else {
        toastr.error('Error', 'Oops something went wrong')
      }
      return Promise.reject(err)
    }
  })

export const completeClientWorkout = (
  { sets, comment, position },
  prevValues,
  workout_id,
  client_id,
  date
) => async () => {
  try {
    const payload = {
      set: {
        progress: {
          ...prevValues,
          real_data: {
            ...prevValues.real_data,
            [date]: { sets, comment, position },
          },
          active: true,
        },
      },
      client_id,
    }

    await Impact.sendClientSet(workout_id, payload)
  } catch (err) {
    throwError(err)
  }
}
