import { createReducer } from '../../utils'
import { SET_NOTIFICATION, LOGIN, LOGOUT } from './actions'

const initialState = {
  jwt: null,
  exp: null,
  role: null,
  notification: false,
}

const userReducer = createReducer(initialState)({
  [LOGIN]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),
  [LOGOUT]: () => initialState,
  [SET_NOTIFICATION]: (state, { payload: client_notification }) => ({
    ...state,
    notification: client_notification,
  }),
})

export default userReducer
