import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import { FETCH_CLIENTS, FETCH_SET } from './actions'

const initialState = {
  data: [],
  loading: false,
  loaded: false,
}

const trainerClientsReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH_CLIENTS),
  ...fetchAndSetApiAction(FETCH_SET),
})

export default trainerClientsReducer
