import { createSelector } from 'reselect'

import { DAYS_OF_WEEK } from '~/utilities/constants'
import { createTaskOptions } from '~/utilities/helpers'

const taskDataSelector = state => state.tasks.data

export const getTasksOptions = createSelector(taskDataSelector, data =>
  Object.entries(
    data.reduce((accumulator, { week_number, day_name, ...rest }) => {
      const byWeek = accumulator
      if (!byWeek[week_number]) {
        byWeek[week_number] = {}
        Object.keys(DAYS_OF_WEEK).forEach(dayName => {
          byWeek[week_number][dayName] = []
        })
      }
      byWeek[week_number][day_name].push(...createTaskOptions(rest))

      return byWeek
    }, {})
  )
    .sort(([keyA], [keyB]) => keyA - keyB)
    .map(([, value]) => value)
)
