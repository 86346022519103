import { Deserializer } from 'jsonapi-serializer'
import { toastr } from 'react-redux-toastr'
import history from '~/history'

import { createApiAction, createAction, throwError } from '~/state/utils'
import Impact from '~/services/Impact'
import { deleteFolderItem } from '~/state/modules/folders/actions'

export const FETCH_PROGRAMS = createApiAction('programs / FETCH_PROGRAMS')
export const CREATE_PROGRAM = 'programs / CREATE_PROGRAM'
export const FETCH_PROGRAM_BY_ID = 'programs / FETCH_PROGRAM_BY_ID'
export const SET_NAME = 'programs / SET_NAME'
export const DELETE_PROGRAM = 'trainer / clients / DELETE_PROGRAM'

export const createProgramAction = createAction(CREATE_PROGRAM)
export const fetchProgramById = createAction(FETCH_PROGRAM_BY_ID)
export const setName = createAction(SET_NAME)
export const deleteProgram = createAction(DELETE_PROGRAM)

export const getAllPrograms = () =>
  Impact.thunk(FETCH_PROGRAMS, async () => {
    const { programs } = await Impact.getAllPrograms()

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(programs)

    return { data }
  })

export const createProgram = data => async dispatch => {
  try {
    const {
      data: { program, message },
    } = await Impact.createProgram(data)
    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(program)
    await dispatch(createProgramAction(payload, message))

    return payload
  } catch (error) {
    throwError(error)
    return Promise.reject(error)
  }
}

export const getProgramById = id => async dispatch => {
  try {
    const { program } = await Impact.getProgramById(id)
    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(program)
    const nutrition_ids = data.nutritions.map(el => el.id)

    dispatch(fetchProgramById({ ...data, nutrition_ids }))
  } catch (err) {
    await history.push('/trainer/programs')
    toastr.warning('Warning', "Can't find program")
  }
}

export const updateProgram = ({
  id,
  nutrition_ids,
  folder_id,
  name,
}) => async dispatch => {
  try {
    const {
      data: { program, message },
    } = await Impact.updateProgram(id, {
      program_id: id,
      nutrition_ids,
      name,
      folder_id,
    })

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(program)

    await dispatch(setName({ ...data, nutrition_ids }, message))
  } catch (error) {
    toastr.error('Error', error.response.data.errors)
    throw Error(error)
  }
}

export const deleteProgramAction = ({ id, folderType }) => async dispatch => {
  try {
    const {
      data: { message },
    } = await Impact.deleteProgram(id)

    await dispatch(deleteFolderItem({ id, folderType }, message))
  } catch (error) {
    await toastr.error('Error', 'Oops something went wrong')
  }
}

export const cloneProgram = values => async dispatch => {
  try {
    const {
      data: { program },
    } = await Impact.cloneProgram(values)

    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(program)

    await dispatch(createProgramAction(payload))
    return payload
  } catch (error) {
    throwError(error)
  }
  return {}
}
