import { Deserializer } from 'jsonapi-serializer'

export const deserializer = (config = { keyForAttribute: 'snake_case' }) => {
  const configuredDeserializer = new Deserializer(config)
  return async data => {
    try {
      return configuredDeserializer.deserialize(data)
    } catch (err) {
      return null
    }
  }
}

function findSerializable(object) {
  if (typeof object !== 'object') return undefined
  let serializableKey
  Object.keys(object).some(key => {
    if (typeof object[key]?.data === 'object') {
      serializableKey = key
      return true
    }
    return false
  })
  return serializableKey
}

export class DeepDeserializer {
  #deserializer

  constructor() {
    this.#deserializer = deserializer()
  }

  async deserialize(object) {
    const result = await this.processOneLevel(object)
    return result
  }

  async processOneLevel(object) {
    try {
      let deserialized =
        typeof object?.data === 'object'
          ? await this.#deserializer(object)
          : null
      let wasArray = true
      if (!Array.isArray(deserialized)) {
        deserialized = [deserialized]
        wasArray = false
      }
      for (let i = 0; i < deserialized.length; i += 1) {
        let key = findSerializable(deserialized[i])
        while (key) {
          // eslint-disable-next-line no-await-in-loop
          deserialized[i][key] = await this.processOneLevel(
            deserialized[i][key]
          )
          key = findSerializable(deserialized[i])
        }
      }
      if (deserialized?.length === 1 && !wasArray) [deserialized] = deserialized
      return deserialized
    } catch (err) {
      return null
    }
  }
}
