import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import { FETCH_GOAL, SET_CLIENT_NOTIFICATION } from './actions'

const initialState = {
  data: {},
  loading: false,
  loaded: false,
}

const goalReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH_GOAL),
  [SET_CLIENT_NOTIFICATION]: (
    state,
    { payload: { trainer_notification, client_notification } }
  ) => ({
    ...state,
    data: { ...state.data, trainer_notification, client_notification },
  }),
})

export default goalReducer
