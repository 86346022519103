import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from '@material-ui/core'

import SubHeader from '~/views/components/SubHeader'

const MainLayout = ({
  children,
  title,
  actionButton,
  titleCard,
  onBack,
  simpleLayout,
}) => (
  <Box flex={1} display="flex" flexDirection="column" mb={6} mt="60px">
    <SubHeader
      title={title}
      simpleLayout={simpleLayout}
      button={actionButton}
      titleCard={titleCard}
      onBack={onBack}
    />
    <Container>{children}</Container>
  </Box>
)

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  title: PropTypes.string,
  titleCard: PropTypes.element,
  actionButton: PropTypes.element,
  onBack: PropTypes.func,
  simpleLayout: PropTypes.bool,
}

MainLayout.defaultProps = {
  title: '',
  children: null,
  titleCard: null,
  actionButton: null,
  onBack: null,
  simpleLayout: false,
}

export default memo(MainLayout)
