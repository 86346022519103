import { Deserializer } from 'jsonapi-serializer'

import { toastr } from 'react-redux-toastr'
import { createApiAction, createAction, throwError } from '~/state/utils'
import Impact from '~/services/Impact'

import { TRAINER } from '~/utilities/constants'

export const FETCH_PROGRESS_PHOTO = createApiAction(
  'progress photo / FETCH_PROGRESS_PHOTO'
)
export const CREATE_PROGRESS_PHOTO = 'progress photo / CREATE_PROGRESS_PHOTO'
export const ADD_IMAGE = 'progress photo / ADD_IMAGE'
export const REMOVE_IMAGE = 'progress photo / REMOVE_IMAGE'

const addImage = createAction(ADD_IMAGE)
const removeImage = createAction(REMOVE_IMAGE)

export const getAllProgressPhoto = (id, role) =>
  Impact.thunk(FETCH_PROGRESS_PHOTO, async () => {
    const { progress_photos } =
      role === TRAINER
        ? await Impact.getAllProgressPhoto(id)
        : await Impact.getAllProgressPhotoClient()

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(progress_photos)

    return { data }
  })

export const createProgressPhoto = (data, role) => async dispatch => {
  try {
    const {
      data: { progress_photo, message },
    } =
      role === TRAINER
        ? await Impact.createProgressPhoto(data)
        : await Impact.createProgressPhotoClient(data)

    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(progress_photo)

    await dispatch(addImage(payload, message))
  } catch (err) {
    throwError(err)
  }
}

export const deleteProgressPhoto = (id, clientId, role) => async dispatch => {
  try {
    if (role === TRAINER) {
      await Impact.deleteProgressPhoto(id, clientId)
    } else {
      await Impact.deleteProgressPhotoClient(id)
    }
    await dispatch(removeImage(id))
    toastr.success('Success', 'Photo deleted successfully')
  } catch (err) {
    toastr.error('Error', 'Oops something went wrong')
  }
}
