import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { connect } from 'react-redux'

import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Container,
  makeStyles,
  IconButton,
  Avatar,
  Box,
  Hidden,
  Grid,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

import Navigation from '../Navigation'
import Link from '~/views/shared/Link'

import LogoSvg from '~/views/components/LogoSvg/LogoSvg'
import Logo from '~/images/logo.png'

const useStyles = makeStyles(theme => ({
  logoLink: {
    flexGrow: 1,
    height: 20,
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontSize: 20,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  titleBar: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  withOnBack: {
    marginRight: 48,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      marginRight: 0,
      width: 'calc(100% - 48px)',
    },
  },
  userName: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    maxWidth: 50,
    height: 'auto',
  },
  button: {
    width: '100%',
    '& button': {
      margin: theme.spacing(1),
    },
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      width: 'fit-content',
    },
  },
}))

const links = [
  {
    name: 'Dashboard',
    path: 'dashboard',
  },
  {
    name: 'Workouts',
    path: 'workouts',
  },
  {
    name: 'Nutrition',
    path: 'nutrition',
  },
  {
    name: 'Measurements',
    path: 'measurements',
    indicator: true,
  },
  {
    name: 'Profile',
    path: 'profile',
  },
]

const DesktopNav = () => {
  const classes = useStyles()

  return (
    <Toolbar disableGutters>
      <Link className={classes.logoLink} to="/">
        <LogoSvg />
      </Link>
      <Navigation links={links} />
    </Toolbar>
  )
}

const MobileNav = ({ title, onBack, name, img, button }) => {
  const classes = useStyles()

  return (
    <>
      <Toolbar disableGutters>
        <Box flex={6} display="flex" alignItems="center">
          <Avatar alt="Client photo" src={img} />
          <Typography className={classes.userName}>{name}</Typography>
        </Box>
        <Box flex={1}>
          <Link to="/">
            <img src={Logo} alt="logo" className={classes.logo} />
          </Link>
        </Box>
      </Toolbar>
      {title && (
        <Toolbar className={classes.titleBar} disableGutters>
          {!!onBack && (
            <IconButton onClick={onBack}>
              <ArrowBack />
            </IconButton>
          )}
          <Typography
            variant="subtitle1"
            className={cx({
              [classes.title]: true,
              [classes.withOnBack]: !!onBack,
            })}
          >
            {title}
          </Typography>
          {button && (
            <Grid item sm={6} xs={12}>
              <Box className={classes.button}>{button}</Box>
            </Grid>
          )}
        </Toolbar>
      )}
    </>
  )
}

const ClientHeader = ({ title, onBack, name, imgSrc, button }) => (
  <MuiAppBar position="sticky">
    <Container>
      <Hidden mdUp>
        <MobileNav
          title={title}
          onBack={onBack}
          name={name}
          img={imgSrc}
          button={button}
        />
      </Hidden>
      <Hidden smDown>
        <DesktopNav />
      </Hidden>
    </Container>
  </MuiAppBar>
)

ClientHeader.propTypes = {
  title: PropTypes.string,
  onBack: PropTypes.func,
  name: PropTypes.string,
  imgSrc: PropTypes.string,
  button: PropTypes.element,
}

ClientHeader.defaultProps = {
  title: '',
  onBack: null,
  name: '',
  imgSrc: '',
  button: null,
}

const mapStateToProps = ({ user: { first_name, last_name, avatar } }) => ({
  name: `${first_name} ${last_name}`,
  imgSrc: avatar?.url,
})

export default connect(mapStateToProps)(memo(ClientHeader))
