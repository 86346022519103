import React, { memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import PropTypes from 'prop-types'
import { Box, Container } from '@material-ui/core'

import { ClientHeader } from '~/views/components/Header'
import { updateClientNotification } from '~/state/modules/user/actions'

const ClientLayoutContainer = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateClientNotification())
  }, [dispatch])

  return <Container>{children}</Container>
}

const MainLayout = ({ children, actionButton, title, onBack }) => (
  <>
    <ClientHeader title={title} onBack={onBack} button={actionButton} />
    <Box flex={1} display="flex" flexDirection="column" mb={15}>
      <ClientLayoutContainer>{children}</ClientLayoutContainer>
    </Box>
  </>
)

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  title: PropTypes.string,
  onBack: PropTypes.func,
  actionButton: PropTypes.element,
}

MainLayout.defaultProps = {
  children: null,
  title: '',
  onBack: null,
  actionButton: null,
}

export default memo(MainLayout)
