import crypto from 'crypto'

const calculateChecksum = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    const md5sum = crypto.createHash('md5')

    reader.readAsBinaryString(file)

    reader.onload = e => {
      md5sum.update(e.target.result, 'binary')
      resolve(md5sum.digest('base64'))
    }

    reader.onerror = e => {
      reject(e)
    }
  })

export default calculateChecksum
