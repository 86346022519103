import Impact from '~/services/Impact'
import { withoutAttributes, createApiAction } from '~/state/utils'

export const FETCH = createApiAction('exercises / FETCH_DATA')

export const getAllExercises = () =>
  Impact.thunk(
    FETCH,
    async () => {
      const {
        data: { exercises },
      } = await Impact.getAllExercises()

      const data = exercises.data.map(withoutAttributes)

      return { data }
    },
    {}
  )
