import { createSelector } from 'reselect'

import { DAYS_OF_WEEK } from '~/utilities/constants'
import { createTaskOptions } from '~/utilities/helpers'

export const getWeekTimeTable = selector =>
  createSelector(selector, programs => {
    const byWeek = Object.keys(DAYS_OF_WEEK).reduce((accumulator, dayName) => {
      accumulator[dayName] = []
      return accumulator
    }, {})

    programs.forEach(program => {
      program.forEach(({ day_name, ...task }) => {
        byWeek[day_name].push(...createTaskOptions(task))
      })
    })

    return byWeek
  })
