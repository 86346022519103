import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, connect, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import { Link as MuiLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { logout } from '~/state/modules/user'

const useStyles = makeStyles({
  navigationMenu: {
    display: 'flex',
    flexGrow: '3',
    justifyContent: 'space-between',
  },
  indicator: {
    position: 'relative',
    '&::after': {
      content: '""',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      display: 'block',
      position: 'absolute',
      right: 0,
      top: 0,
      marginRight: '-10px',
    },
  },
  unread: {
    '&::after': {
      backgroundColor: '#ff2828',
    },
  },
})

const Navigation = ({ links, role }) => {
  const dispatch = useDispatch()
  const isUnread = useSelector(state => state.user.notification)
  const classes = useStyles()

  return (
    <nav className={classes.navigationMenu}>
      {links.map(({ name, indicator, path }) => (
        <MuiLink
          key={name}
          className={cx({
            [classes.indicator]: indicator,
            [classes.unread]: isUnread,
          })}
          component={NavLink}
          to={`/${role}/${path}`}
          underline="none"
        >
          {name}
        </MuiLink>
      ))}
      <MuiLink onClick={() => dispatch(logout())} underline="none">
        Logout
      </MuiLink>
    </nav>
  )
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({ name: PropTypes.string, path: PropTypes.string })
  ).isRequired,
  role: PropTypes.string.isRequired,
}

export default connect(({ user: { role } }) => ({ role }))(Navigation)
