import { toastr } from 'react-redux-toastr'

import { createAction, throwError } from '~/state/utils'
import Impact from '~/services/Impact'
import { DeepDeserializer, deserializer } from '../../../services/Deserializer'

import { FOLDER_TO_FIELD } from '~/utilities/constants'
import { deepMapKeys } from '../../../utilities/helpers'

export const FETCH_FOLDERS = 'folders / FETCH_FOLDERS'
export const CREATE_FOLDER = 'folders / CREATE_FOLDER'
export const DELETE_FOLDER = 'folders / DELETE_FOLDER'
export const UPDATE_FOLDER = 'folders / UPDATE_FOLDER'
export const UPDATE_FOLDER_ITEM = 'folders / UPDATE_FOLDER_ITEM'
export const SET_FOLDER = 'folders / SET_FOLDER'
export const SET_FOLDER_ITEMS = 'folders / SET_FOLDER / ITEMS'
export const DELETE_FOLDER_ITEM = 'folders / DELETE_FOLDER_ITEM / ITEM'
export const SET_ONE_FOLDER_ITEM = 'folders / SET_FOLDER / ONE / ITEM'
export const RESET_CURRENT_FOLDER = 'folder / RESET_CURRENT_FOLDER'
export const RESET_BREAD_CRUMBS = 'folder / RESET_BREAD_CRUMBS'
export const SET_FOLDERS_TREE = 'folder / SET_FOLDERS_TREE'
export const SET_ALL_DATA = 'folder / SET_ALL_DATA'
export const UPDATE_FOLDERS_TREE = 'folder / UPDATE_FOLDERS_TREE'

export const fetchFolders = createAction(FETCH_FOLDERS)
export const deleteFolder = createAction(DELETE_FOLDER)
export const deleteFolderItem = createAction(DELETE_FOLDER_ITEM)
export const updateFolderAction = createAction(UPDATE_FOLDER)
export const setFolder = createAction(SET_FOLDER)
export const updateFolderItem = createAction(UPDATE_FOLDER_ITEM)
export const setFolderItems = createAction(SET_FOLDER_ITEMS)
export const setAllData = createAction(SET_ALL_DATA)
export const setOneFolderItem = createAction(SET_ONE_FOLDER_ITEM)
export const createFolderAction = createAction(CREATE_FOLDER)
export const resetCurrentFolder = createAction(RESET_CURRENT_FOLDER)
export const setFoldersTree = createAction(SET_FOLDERS_TREE)
export const updateFoldersTree = createAction(UPDATE_FOLDERS_TREE)

const itemsSource = {
  program: Impact.getAllPrograms,
  workout: Impact.getAllWorkouts,
  nutrition: Impact.getAllNutrition,
}

const searchItemsSource = {
  program: Impact.getProgramsBySearch,
  workout: Impact.getWorkoutsBySearch,
  nutrition: Impact.getNutritionBySearch,
}

export const getFoldersByType = folder_type => async dispatch => {
  try {
    const { folders } = await Impact.getFoldersByType(folder_type)
    const data = await deserializer()(folders)

    dispatch(fetchFolders({ data, folder_type }))
  } catch (err) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const getFoldersRoot = folder_type => async dispatch => {
  try {
    dispatch(getFoldersByType(folder_type))
    const itemsRequest = itemsSource[folder_type]
    const { [FOLDER_TO_FIELD[folder_type]]: items } = await itemsRequest({
      without_folder: true,
    })

    const data = await deserializer()(items)

    dispatch(setFolderItems({ data, folder_type, root: true } || []))
    dispatch(setAllData({ data, folder_type } || []))
  } catch (err) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const getFoldersTree = folder_type => async (dispatch, getState) => {
  const isLoaded =
    Object.keys(getState().folders[folder_type].foldersTree).length > 0

  if (isLoaded) return
  try {
    const deserializerInstance = new DeepDeserializer()

    const { folders } = await Impact.getFoldersByType(folder_type)
    const sub_folders = await deserializerInstance.deserialize(folders)

    const itemsRequest = itemsSource[folder_type]

    const mapKey = { [FOLDER_TO_FIELD[folder_type]]: 'items' }
    const mappedSub = deepMapKeys({ sub_folders }, key => mapKey[key] || key)

    const { [FOLDER_TO_FIELD[folder_type]]: items } = await itemsRequest({
      without_folder: true,
    })

    const data = await deserializer()(items)

    const foldersTree = {
      folder_type: 'root',
      folders: mappedSub.sub_folders,
      items: data || [],
    }

    dispatch(setFoldersTree({ foldersTree, folder_type }))
  } catch (err) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const updateFoldersTreeAction = ({ id, type, parentIds }) => async (
  dispatch,
  getState
) => {
  try {
    const isLoaded = getState().folders[type].loadedNodesIds.includes(id)
    if (isLoaded) return
    const { folder } = await Impact.getFolderById(id)
    const {
      [FOLDER_TO_FIELD[type]]: items,
      ...data
    } = await new DeepDeserializer().deserialize(folder)

    const foldersNode = {
      items,
      ...data,
    }

    dispatch(updateFoldersTree({ foldersNode, type, parentIds, id }))
  } catch (err) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const getFolderById = id => async dispatch => {
  try {
    const { folder, parent_folders } = await Impact.getFolderById(id)
    const {
      folders,
      parent_id,
      parent_name,
      folder_type,
      id: folderId,
      name: folderName,
      ...rest
    } = await new DeepDeserializer().deserialize(folder)

    dispatch(
      setFolder({
        data: folders,
        parent_id,
        folderId,
        parent_name,
        folderName,
        parent_folders,
        folder_type,
      })
    )

    dispatch(
      setFolderItems(
        {
          data: rest[FOLDER_TO_FIELD[folder_type]],
          folder_type,
        } || {
          data: [],
          folder_type,
        }
      )
    )
  } catch (err) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const createFolder = data => async dispatch => {
  try {
    const {
      data: { folder },
      status,
    } = await Impact.createFolder(data)
    const payload = await deserializer()(folder)

    await dispatch(createFolderAction(payload))
    if (status === 200) {
      toastr.success('Success', 'New folder added')
    }
    return payload
  } catch (error) {
    throwError(error)
    return Promise.reject(error)
  }
}

export const updateFolder = data => async dispatch => {
  try {
    const {
      data: { folder },
      status,
    } = await Impact.updateFolder(data.id, { folder: { ...data } })

    const payload = await deserializer()(folder)

    if (status === 200) {
      dispatch(updateFolderAction(payload))
      toastr.success('Success', 'Folder updated')
    }
  } catch (err) {
    toastr.error('Error', err.response.data.errors)
  }
}

export const copyFolder = data => async dispatch => {
  try {
    const {
      data: { folder },
      status,
    } = await Impact.copyFolder(data)
    const payload = await deserializer()(folder)

    if (status === 200) {
      dispatch(createFolderAction(payload))
      toastr.success('Success', 'Folder copied')
    }
  } catch (error) {
    toastr.error('Error', error.response.data.errors)
  }
}

export const deleteFolderAction = (id, type) => async dispatch => {
  try {
    const {
      data: { message },
    } = await Impact.deleteFolder(id)
    await dispatch(deleteFolder({ id, type }, message))
  } catch (error) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const findFolders = (folder_type, search_value) => async dispatch => {
  try {
    const { folders } = await Impact.getFoldersBySearch(
      folder_type,
      search_value
    )

    const data = await deserializer()(folders)

    dispatch(fetchFolders({ data, folder_type }))
  } catch (error) {
    toastr.error('Error', 'Oops something went wrong')
  }
}

export const findItems = (folder_type, search_value) => async dispatch => {
  try {
    const itemsRequest = searchItemsSource[folder_type]

    const { [FOLDER_TO_FIELD[folder_type]]: items } = await itemsRequest(
      search_value
    )
    const data = await deserializer()(items)

    dispatch(setFolderItems({ data, folder_type } || []))
    dispatch(setAllData({ data, folder_type } || []))
  } catch (error) {
    toastr.error('Error', 'Oops something went wrong')
  }
}
