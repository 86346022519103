import { createSelector } from 'reselect'

const foldersSelector = type => state => state.folders[type].data
const itemsSelector = type => state => state.folders[type].items

export const getFoldersData = type =>
  createSelector(
    foldersSelector(type),
    itemsSelector(type),
    (folders, items) => {
      const foldersWithUid = folders.map(f => ({ ...f, uID: `folder_${f.id}` }))
      const itemsWithUid = items.map(i => ({ ...i, uID: `item_${i.id}` }))

      return [...foldersWithUid, ...itemsWithUid]
    }
  )
