import { Deserializer } from 'jsonapi-serializer'

import { TRAINER } from '~/utilities/constants'

import { createApiAction, createAction, throwError } from '~/state/utils'
import Impact from '~/services/Impact'

export const FETCH_MEASUREMENTS = createApiAction(
  'measurements / FETCH_MEASUREMENTS'
)
export const CREATE_MEASUREMENT = 'measurements / CREATE_MEASUREMENT'
export const ADD_MEASURE = 'measurements / ADD_MEASURE'
export const REMOVE_MEASURE = 'measurements / REMOVE_MEASURE'

const addMeasure = createAction(ADD_MEASURE)
const removeAction = createAction(REMOVE_MEASURE)

export const getAllMeasurements = (id, role) =>
  Impact.thunk(FETCH_MEASUREMENTS, async () => {
    const { measures } =
      role === TRAINER
        ? await Impact.getAllMeasurements(id)
        : await Impact.getAllClientMeasurements()

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(measures)

    return { data }
  })

export const createMeasure = (data, role) => async dispatch => {
  try {
    const {
      data: { measure, message },
    } =
      role === TRAINER
        ? await Impact.createMeasurement(data)
        : await Impact.createMeasurementClient(data)

    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(measure)

    await dispatch(addMeasure(payload, message))
  } catch (err) {
    throwError(err)
  }
}

export const removeMeasure = (measureId, clientId, role) => async dispatch => {
  try {
    const response =
      role === TRAINER
        ? await Impact.removeMeasurement(measureId, clientId)
        : await Impact.removeMeasurementClient(measureId)

    const { message } = response.data

    dispatch(removeAction(measureId, message))
  } catch (err) {
    throwError(err)
  }
}
