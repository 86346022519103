import { createSelector } from 'reselect'

const nutritionDataSelector = state => state?.nutrition?.data

export const getNutritionOptions = createSelector(
  nutritionDataSelector,
  data => {
    if (Array.isArray(data)) {
      return data.map(({ name, id }) => ({ label: name, value: id }))
    }
    return []
  }
)
const nutritionDataWithFoldersSelector = state => state.folders.nutrition

export const getNutritionData = createSelector(
  nutritionDataWithFoldersSelector,
  ({ data, items, currentFolder }) => ({
    data: [
      ...data.map(({ name, id, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
      })),
      ...items.map(({ name, id, ...rest }) => ({
        label: name,
        value: id,
        ...rest,
      })),
    ],
    currentFolder,
  })
)
