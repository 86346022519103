import { Deserializer } from 'jsonapi-serializer'

import { toastr } from 'react-redux-toastr'
import history from '~/history'
import Impact from '~/services/Impact'
import { throwError, createApiAction, createAction } from '~/state/utils'
import { DeepDeserializer, deserializer } from '~/services/Deserializer'
import { TRAINER, FOLDERS_TYPES } from '~/utilities/constants'
import {
  setOneFolderItem,
  deleteFolderItem,
} from '~/state/modules/folders/actions'

//*  Action constants *//
export const UPDATE_WORKOUT = 'workout / UPDATE_WORKOUT'
export const CREATE_WORKOUT = 'workout / CREATE_WORKOUT'
export const ADD_WORKOUT_EXERCISE = 'workout / exercises / ADD_WORKOUT_EXERCISE'
export const DELETE_WORKOUT_EXERCISES =
  'workout / exercises/ DELETE_WORKOUT_EXERCISES'
export const UPDATE_WORKOUT_EXERCISE =
  'workout / exercises / UPDATE_WORKOUT_EXERCISE'
export const CLONE_WORKOUT = 'workout / CLONE_WORKOUT'

//* API  actions *//
export const FETCH_WORKOUTS = createApiAction('workout / FETCH_WORKOUTS')
export const GET_ONE = createApiAction('workout / GET_ONE')
export const createWorkoutAction = createAction(CREATE_WORKOUT)
export const addWorkoutExerciseAction = createAction(ADD_WORKOUT_EXERCISE)
export const updateWorkoutData = createAction(UPDATE_WORKOUT_EXERCISE)

export const deleteWorkoutExerciseAction = createAction(
  DELETE_WORKOUT_EXERCISES
)
export const updateWorkoutAction = createAction(UPDATE_WORKOUT)

export const createWorkout = data => async dispatch => {
  try {
    const {
      data: { workout, message },
    } = await Impact.createWorkout(data)
    const folderType = FOLDERS_TYPES.workout
    const payload = await deserializer()(workout)

    dispatch(setOneFolderItem({ folderType, payload }, message))
    return payload
  } catch (error) {
    throwError(error)
  }
  return {}
}

export const deleteWorkout = ({ id, folderType }) => async dispatch => {
  try {
    const {
      data: { message },
    } = await Impact.deleteWorkout(id)
    await dispatch(deleteFolderItem({ id, folderType }, message))
  } catch (error) {
    throwError(error)
  }
}

export const addWorkoutExercise = (
  id,
  exercises,
  { grouped_exercises } = {}
) => async dispatch => {
  const { exercise_ids = [] } = exercises

  try {
    const {
      data: { workout_exercise, message },
    } = await Impact.createWorkoutExercise({
      workout_exercise: { workout_id: id, exercise_ids },
      grouped_exercises,
    })

    let payload = await deserializer()(workout_exercise)

    if (!Array.isArray(payload)) payload = [payload]

    await dispatch(addWorkoutExerciseAction(payload, message))
  } catch (error) {
    throwError(error)
  }
}

export const deleteWorkoutExercise = id => async dispatch => {
  try {
    const {
      data: { message },
    } = await Impact.deleteWorkoutExercise(id)

    await dispatch(deleteWorkoutExerciseAction(id, message))
  } catch (error) {
    toastr.error('Error', 'Oops something went wrong!')
  }
}

export const getWorkout = id =>
  Impact.thunk(GET_ONE, async () => {
    try {
      const { workout } = await Impact.getWorkout(id)
      const data = await new Deserializer({
        keyForAttribute: 'snake_case',
        transform: async workoutItem => {
          const { workout_exercises: workoutExercise, ...rest } = workoutItem
          workoutExercise.sort(({ position: a }, { position: b }) => a - b)

          return { workoutExercise, ...rest }
        },
      }).deserialize(workout)
      if (!data) throw Error('Invalid data structure')
      return { data }
    } catch (err) {
      await history.push('/trainer/workouts')
      if (err.response.status === 404) {
        toastr.warning('Warning', "Can't find workout")
      } else {
        toastr.error('Error', 'Oops something went wrong')
      }

      return Promise.reject(err)
    }
  })

export const getAllWorkouts = () =>
  Impact.thunk(FETCH_WORKOUTS, async () => {
    const { workouts } = await Impact.getAllWorkouts()

    const data = await new DeepDeserializer().deserialize(workouts)

    return { data }
  })

export const getAllTrainersWorkouts = role =>
  Impact.thunk(FETCH_WORKOUTS, async () => {
    const { workouts } =
      role === TRAINER
        ? await Impact.getAllTrainersWorkouts()
        : await Impact.getAllTrainersWorkoutsClient()

    const data = await new DeepDeserializer().deserialize(workouts)

    return { data }
  })

export const updateWorkout = ({ id, name, folder_id }) => async dispatch => {
  try {
    const {
      data: { workout, message },
    } = await Impact.updateWorkout(id, { name, folder_id })

    const data = await deserializer()(workout)

    await dispatch(updateWorkoutAction(data, message))
  } catch (err) {
    toastr.error('Error', `${err.response.data.errors}`)
    throw Error(err)
  }
}

export const updateWorkoutExercise = (values, id) => async dispatch => {
  try {
    const { progresses, workout_exercises: workoutExercises } = values

    const progressData = progresses.reduce((acc, cur) => ({
      ...acc,
      ...cur,
    }))

    const positionData = workoutExercises.reduce(
      (acc, { position, id: exerciseId }) => ({
        ...acc,
        [`position_${exerciseId}`]: position,
      }),
      {}
    )
    const {
      data: { workout_exercise, message },
    } = await Impact.updateWorkoutExercise(id, {
      workout_exercises: { ...progressData, ...positionData },
    })

    const payload = await deserializer()(workout_exercise)

    payload.sort(({ id: a }, { id: b }) => a - b)

    await dispatch(updateWorkoutData(payload, message))
  } catch (error) {
    if (error?.message && !error.response?.data?.errors) throw error
    throwError(error)
  }
}

export const cloneWorkout = values => async dispatch => {
  try {
    const {
      data: { workout },
    } = await Impact.cloneWorkout(values)
    const payload = await deserializer()(workout)
    await dispatch(setOneFolderItem({ payload, folderType: workout.data.type }))
    return payload
  } catch (error) {
    throwError(error)
  }
  return {}
}
