export const API_URL = process.env.REACT_APP_IMPACT_API

export const STORAGE = {
  authToken: 'jwt_token',
  exp: 'exp_date',
  sets: (id, date) => `sets_${id}_${date || ''}`,
}

export const TIMEOUT = 15 * 60 * 1000

// User roles
export const CLIENT = 'client'
export const TRAINER = 'trainer'

export const MOMENT_DAYS_SHIFT = {
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  0: 6,
}
// Folders types
export const FOLDERS_TYPES = {
  program: 'program',
  workout: 'workout',
  nutrition: 'nutrition',
}
export const FOLDERS_NAMES = {}
export const FOLDER_TO_FIELD = {
  program: 'programs',
  workout: 'workouts',
  nutrition: 'nutritions',
}

// Timetable constants
export const DAYS_OF_WEEK = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
}

export const TIMETABLE_OPTIONS = {
  progress_photo: 'Progress photo',
  update_measurements: 'Measurements',
  rest_day: 'Rest day',
  group_training: 'Group training',
  step_target: 'Step target',
  weigh_in: 'Weigh-in',
  check_in: 'Check-in',
}

export const MEASUREMENTS_OPTIONS = {
  progress_photo: 'Progress photo',
  update_measurements: 'Measurements',
  rest_day: 'Rest day',
  step_target: 'Step target',
  weigh_in: 'Weigh-in',
}

export const TIMETABLE_KEYS = {
  progress_photo: 'progress_photo',
  update_measurements: 'update_measurements',
  rest_day: 'rest_day',
  group_training: 'group_training',
  step_target: 'step_target',
  weigh_in: 'weigh_in',
  check_in: 'check_in',
}
export const TIMETABLE_ACTIVITIES = {
  step_target: 'step_target',
  weigh_in: 'weigh_in',
}
export const TIMETABLE_OPTIONS_ORDER = {
  workout: 1,
  group_training: 2,
  update_measurements: 3,
  step_target: 4,
  progress_photo: 5,
  check_in: 6,
  weigh_in: 6,
}
export const TIMETABLE_TAB_INDEX = {
  progress_photo: '1',
  update_measurements: '2',
  step_target: '2',
  weigh_in: '2',
  check_in: '0',
}

// Public pages
export const PUBLIC_PAGES = [
  '/login',
  '/forgot-password',
  '/auth/reset-password',
]

export const ACCOUNT_TYPE = {
  clients: 'client',
  trainers: 'trainer',
}
