import React from 'react'
import { Box, Hidden } from '@material-ui/core'

import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIcon from '@material-ui/icons/Assignment'
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import PersonIcon from '@material-ui/icons/Person'

import loadable from '@loadable/component'
import { TrainerHeader } from '~/views/components/Header'

const BottomMenu = loadable(() =>
  import('~/views/components/BottomMenu' /* webpackChunkName: "bottom-menu" */)
)

const bottomLinks = [
  {
    Icon: HomeIcon,
    path: '/trainer/dashboard',
  },
  {
    Icon: PeopleIcon,
    path: '/trainer/clients',
  },
  {
    Icon: AssignmentIcon,
    path: '/trainer/programs',
  },
  {
    Icon: FitnessCenterIcon,
    path: '/trainer/workouts',
  },
]

const sidebarLinks = [
  {
    Icon: RestaurantMenuIcon,
    path: '/trainer/nutrition',
    name: 'Nutrition',
  },
  {
    Icon: PersonIcon,
    path: '/trainer/profile',
    name: 'Profile',
  },
]

const RoutesWrapper = ({ children }) => (
  <Box display="flex" flexDirection="column" minHeight="100vh">
    <TrainerHeader />
    {children}

    <Hidden mdUp>
      <BottomMenu bottomLinks={bottomLinks} sidebarLinks={sidebarLinks} />
    </Hidden>
  </Box>
)

export default RoutesWrapper
