import { toastr } from 'react-redux-toastr'

import { deserializer, DeepDeserializer } from '~/services/Deserializer'
import Impact from '~/services/Impact'

import { getDayByStartDate } from '~/utilities/helpers'
import { createApiAction, createAction, throwError } from '~/state/utils'

// Action constants
export const FETCH_PROGRAMS_FOR_CLIENT = createApiAction(
  'trainer / clients / FETCH_PROGRAMS_FOR_CLIENT'
)
export const SET_PROGRAMS = 'trainer / clients / SET_PROGRAMS'

export const setPrograms = createAction(SET_PROGRAMS)

export const getProgramsForClient = (id, message) =>
  Impact.thunk(FETCH_PROGRAMS_FOR_CLIENT, async (_, dispatch) => {
    try {
      const { client_programs: programData } = await Impact.getProgramsById(id)

      const tasksMappedToPrograms = {}
      const data = await deserializer({
        keyForAttribute: 'snake_case',

        transform: async programItem => {
          const {
            tasks,
            program: programSerialized,
            id: programId,
            ...rest
          } = programItem

          const resp = await Impact.getTaskById(rest.program_id, id)
          const workouts = await deserializer()(resp.tasks)

          const taskDataWithDate = workouts.map(item => ({
            ...item,
            date: getDayByStartDate(
              rest.start_date,
              item.week_number,
              item.day_name
            ).format('L'),
          }))

          tasksMappedToPrograms[programId] = taskDataWithDate

          return {
            program: { ...programSerialized },
            id: programId,
            ...rest,
          }
        },
      })(programData)

      dispatch(setPrograms({ programs: tasksMappedToPrograms }, message))

      return { data }
    } catch (err) {
      return { data: [] }
    }
  })

export const assignClientToProgram = ({
  client_id,
  ...values
}) => async dispatch => {
  try {
    const {
      data: { message },
    } = await Impact.createClientProgram({
      program: { ...values },
      client_id,
    })

    await dispatch(getProgramsForClient(client_id, message))
  } catch (err) {
    throwError(err)
  }
}

export const unassingClientFromProgram = (client_id, id) => async dispatch => {
  try {
    await Impact.deleteClientProgram(id, { client_id })
    await dispatch(getProgramsForClient(client_id))
    toastr.success('Success', 'Program was removed from client')
  } catch (err) {
    throwError(err)
  }
}

export const getClientProgram = () =>
  Impact.thunk(FETCH_PROGRAMS_FOR_CLIENT, async () => {
    const { client_programs } = await Impact.getClientProgram()
    const data = await new DeepDeserializer().deserialize(client_programs)

    return { data }
  })
