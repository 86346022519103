import { deserializer, DeepDeserializer } from '~/services/Deserializer'

import Impact from '~/services/Impact'

import { createApiAction } from '~/state/utils'

export const FETCH_DASHBOARD = createApiAction('trainer / FETCH_DASHBOARD')

export const getTrainerDashboard = () =>
  Impact.thunk(FETCH_DASHBOARD, async () => {
    const {
      clients_without_program,
      last_program_ends_in_a_week,
      new_clients_without_program,
      client_comments_with_trainer_notification,
    } = await Impact.getTrainerDashboard()

    const dataDeserializer = deserializer()

    const newClientsWithoutProgram = await dataDeserializer(
      new_clients_without_program
    )

    const clientsWithoutProgram = await dataDeserializer(
      clients_without_program
    )

    const lastProgram = await dataDeserializer(last_program_ends_in_a_week)

    let unreadClientComments = await new DeepDeserializer().deserialize(
      client_comments_with_trainer_notification
    )
    if (unreadClientComments) {
      unreadClientComments = Object.values(
        unreadClientComments
          .map(({ client }) => client)
          .reduce((acc, el) => ({ [el.id]: el, ...acc }), {})
      )
    } else {
      unreadClientComments = []
    }

    return {
      data: {
        newClientsWithoutProgram,
        clientsWithoutProgram,
        lastProgram,
        unreadClientComments,
      },
    }
  })
