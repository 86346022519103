import { createReducer, fetchAndSetApiAction } from '~/state/utils'
import { FETCH } from './actions'

const initialState = {
  data: [],
  loading: false,
  loaded: false,
}

const exercisesReducer = createReducer(initialState)({
  ...fetchAndSetApiAction(FETCH),
})

export default exercisesReducer
