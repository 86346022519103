import { toastr } from 'react-redux-toastr'
import history from '~/history'
import { deserializer } from '~/services/Deserializer'

import Impact from '~/services/Impact'
import { createApiAction, throwError } from '~/state/utils'

export const FETCH = createApiAction('client_workout / FETCH_DATA')

export const getClientWorkout = (id, programId) =>
  Impact.thunk(FETCH, async () => {
    try {
      const { set, workout } = await Impact.getClientWorkout(id, programId)

      let setData = await deserializer()(set)
      setData = setData.map(item => {
        if (Array.isArray(item.progress))
          return { ...item, progress: { default: item.progress } }
        return item
      })

      const workoutData = await deserializer()(workout)

      return {
        data: {
          set: setData,
          workout: workoutData,
        },
      }
    } catch (err) {
      await history.push(`/client/workouts`)
      if (err.response.status === 404) {
        toastr.warning('Warning', "Can't find workout")
      } else {
        toastr.error('Error', 'Oops something went wrong')
      }
      return Promise.reject(err)
    }
  })

export const completeWorkout = (
  { sets, comment, position },
  prevValues,
  id,
  date
) => async () => {
  try {
    const payload = {
      set: {
        progress: {
          ...prevValues,
          real_data: {
            ...prevValues.real_data,
            [date]: { sets, comment, position },
          },
          active: true,
        },
      },
    }

    await Impact.sendSet(id, payload)
  } catch (err) {
    throwError(err)
  }
}
