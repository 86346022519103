import Impact from '~/services/Impact'
import { createApiAction, createAction, throwError } from '~/state/utils'
import { TRAINER, CLIENT } from '~/utilities/constants'
import { DeepDeserializer } from '~/services/Deserializer'

export const FETCH = createApiAction('client_history / FETCH_DATA')
export const GET_STEPS_COUNT = createApiAction(
  'client_history / GET_STEPS_COUNT'
)

export const getClientHistory = (id, role) =>
  Impact.thunk(FETCH, async () => {
    const { client_programs } =
      role === TRAINER
        ? await Impact.getClientHistory(id)
        : await Impact.getClientHistoryClient(id)

    return { data: client_programs }
  })

export const getClientStepsCount = (
  id,
  isStepCount,
  role
) => async dispatch => {
  try {
    const { measures } =
      role === CLIENT
        ? await Impact.getAllClientMeasurements(isStepCount)
        : await Impact.getAllMeasurements(id, isStepCount)

    const payload = await new DeepDeserializer().deserialize(measures)
    await dispatch(createAction(GET_STEPS_COUNT)(payload))
  } catch (err) {
    throwError(err)
  }
}
