import { createSelector } from 'reselect'
import moment from 'moment'

const nutritionsDataSelector = state => state.clientNutrition.data

export const getNutritions = createSelector(nutritionsDataSelector, data => {
  const formattedData = data.map(({ program }) => program.nutritions).flat(1)

  return Object.values(
    formattedData.reduce((acc, el) => ({ ...acc, [el.id]: el }), {})
  )
})

export const getFollowedNutrition = createSelector(
  nutritionsDataSelector,
  data =>
    data.map(({ followed_nutrition = {} }) => {
      if (followed_nutrition) {
        const isToday = Object.keys(followed_nutrition).some(date =>
          moment(date).isSame(moment(), 'day')
        )
        return isToday
      }
      return false
    })
)
