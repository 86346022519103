import objectPath from 'object-path'
import { createReducer } from '~/state/utils'
import { sortByField } from '~/utilities/helpers'
import {
  CREATE_FOLDER,
  DELETE_FOLDER,
  FETCH_FOLDERS,
  RESET_CURRENT_FOLDER,
  SET_FOLDER,
  SET_FOLDERS_TREE,
  SET_FOLDER_ITEMS,
  UPDATE_FOLDER,
  SET_ONE_FOLDER_ITEM,
  UPDATE_FOLDER_ITEM,
  DELETE_FOLDER_ITEM,
  SET_ALL_DATA,
  UPDATE_FOLDERS_TREE,
} from './actions'

window.objectPath = objectPath

const initialState = {
  program: {
    data: [],
    items: [],
    name: 'Programs',
    allData: [],
    currentFolder: undefined,
    foldersTree: {},
    parent_folders: [],
    loading: false,
    loaded: false,
    loadedNodesIds: [],
  },
  workout: {
    data: [],
    items: [],
    name: 'Workouts',
    allData: [],
    currentFolder: undefined,
    foldersTree: {},
    parent_folders: [],
    loading: false,
    loaded: false,
    loadedNodesIds: [],
  },
  nutrition: {
    data: [],
    items: [],
    name: 'Nutrition',
    allData: [],
    currentFolder: undefined,
    foldersTree: {},
    parent_folders: [],
    loading: false,
    loaded: false,
    loadedNodesIds: [],
  },
}

const foldersReducer = createReducer(initialState)({
  [FETCH_FOLDERS]: (state, { payload: { data, folder_type } }) => {
    const sortedData = sortByField(data, 'name')

    return {
      ...state,
      [folder_type]: {
        ...state[folder_type],
        data: sortedData,
        loading: false,
        loaded: true,
        parent_folders: [],
      },
    }
  },
  [CREATE_FOLDER]: (state, { payload }) => {
    const data = [...state[payload.folder_type].data, payload]
    return {
      ...state,
      [payload.folder_type]: {
        ...state[payload.folder_type],
        data: payload.parent_id ? data : sortByField(data, 'name'),
      },
    }
  },
  [SET_FOLDER]: (
    state,
    {
      payload: {
        data,
        folderId,
        folderName,
        parent_id,
        parent_name,
        folder_type,
        parent_folders,
      },
    }
  ) => ({
    ...state,
    [folder_type]: {
      ...state[folder_type],
      data,
      currentFolder: { folderId, folderName, parent_id, parent_name },
      parent_folders,
      loading: false,
      loaded: true,
    },
  }),
  [SET_FOLDER_ITEMS]: (state, { payload: { data, folder_type, root } }) => ({
    ...state,
    [folder_type]: {
      ...state[folder_type],
      items: root ? sortByField(data, 'name') : data,
    },
  }),
  [SET_ALL_DATA]: (state, { payload: { data, folder_type } }) => ({
    ...state,
    [folder_type]: {
      ...state[folder_type],
      allData: data,
    },
  }),
  [SET_ONE_FOLDER_ITEM]: (state, { payload: { payload, folderType } }) => ({
    ...state,
    [folderType]: {
      ...state[folderType],
      items: [...state[folderType].items, payload],
    },
  }),
  [DELETE_FOLDER]: (state, { payload: { id, type } }) => ({
    ...state,
    [type]: {
      ...state[type],
      data: state[type].data.filter(el => el.id !== id),
    },
  }),
  [UPDATE_FOLDER]: (state, { payload }) => {
    const currentFolderIndex = state[payload.folder_type].data.findIndex(
      fol => fol.id === payload.id
    )
    const isParentChanged =
      state[payload.folder_type].data[currentFolderIndex].parent_id !==
      payload.parent_id

    const data = [
      ...state[payload.folder_type].data.slice(0, currentFolderIndex),
      ...(isParentChanged ? [] : [payload]),
      ...state[payload.folder_type].data.slice(currentFolderIndex + 1),
    ]

    return {
      ...state,
      [payload.folder_type]: {
        ...state[payload.folder_type],
        data: payload.parent_id ? data : sortByField(data, 'name'),
        foldersTree: {},
        loadedNodesIds: [],
      },
    }
  },
  [RESET_CURRENT_FOLDER]: (state, { payload }) => ({
    ...state,
    [payload]: { ...state[payload], currentFolder: undefined },
  }),

  [SET_FOLDERS_TREE]: (state, { payload: { foldersTree, folder_type } }) => {
    const sortedFolders = sortByField(foldersTree.folders, 'name')
    const sortedItems = sortByField(foldersTree.items, 'name')
    return {
      ...state,
      [folder_type]: {
        ...state[folder_type],
        foldersTree: {
          folders: sortedFolders,
          items: sortedItems,
          folder_type,
        },
      },
    }
  },

  [UPDATE_FOLDERS_TREE]: (
    state,
    { payload: { foldersNode, type, parentIds, id } }
  ) => {
    const copyFolderTree = JSON.parse(JSON.stringify(state[type].foldersTree))

    objectPath.set(
      copyFolderTree,
      `folders.${parentIds.join('.folders.')}`,
      foldersNode
    )

    return {
      ...state,
      [type]: {
        ...state[type],
        foldersTree: copyFolderTree,
        loadedNodesIds: [...state[type].loadedNodesIds, id],
      },
    }
  },

  [UPDATE_FOLDER_ITEM]: (state, { payload: { folderType, ...payload } }) => {
    const item = state[folderType].items.find(el => el.id === payload.id)
    if (item.folder_id === payload.folder_id) return state

    return {
      ...state,
      [folderType]: {
        ...state[folderType],
        items: state[folderType].items.filter(({ id }) => id !== payload.id),
        foldersTree: {},
        loadedNodesIds: [],
      },
    }
  },
  [DELETE_FOLDER_ITEM]: (state, { payload: { id, folderType } }) => ({
    ...state,
    [folderType]: {
      ...state[folderType],
      items: state[folderType].items.filter(({ id: i }) => i !== id),
    },
  }),
})

export default foldersReducer
