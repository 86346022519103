import jwtDecode from 'jwt-decode'
import { Deserializer } from 'jsonapi-serializer'

import Impact from '~/services/Impact'
import LocalStorage from '~/services/LocalStorage'
import { STORAGE, TIMEOUT, TRAINER } from '~/utilities/constants'
import { getLocalLoggedStatus, setLocalUser } from './utils'

import { throwError, createAction } from '~/state/utils'
import history from '~/history'

// Action constants
export const LOGOUT = 'user / LOGOUT'
export const LOGIN = 'user / LOGIN'
export const SET_NOTIFICATION = 'user / SET_NOTIFICATION'

export const loggedOut = createAction(LOGOUT)

export const setNotification = createAction(SET_NOTIFICATION)

export const authorize = (data, token) => async dispatch => {
  const storage = new LocalStorage()
  let decodedToken
  Impact.setAuthToken(token)

  if (token) {
    storage.add(STORAGE.authToken, token)
    decodedToken = jwtDecode(token)
  }

  dispatch({
    type: LOGIN,
    payload: {
      ...decodedToken,
      ...data,
      token,
    },
  })
}

export const logout = () => dispatch => {
  const storage = new LocalStorage()

  storage.clear()
  Impact.setAuthToken()

  history.push('/')

  dispatch(loggedOut())
}

export const refreshUser = (resp, role) => async dispatch => {
  const { jwt } = resp.data

  const payload = await new Deserializer({
    keyForAttribute: 'snake_case',
  }).deserialize(resp.data[role])

  await dispatch(authorize(payload, jwt))
}

export const login = (user, data) => async dispatch => {
  try {
    const resp = await Impact.login(user, data)
    const logged = getLocalLoggedStatus()
    const userType = user.slice(0, -1)

    await dispatch(refreshUser(resp, userType))

    if (!logged) setLocalUser(resp.data.jwt, TIMEOUT)
  } catch (error) {
    throwError(error)
  }
}

export const refreshToken = token => async dispatch => {
  const logged = getLocalLoggedStatus()
  const user = jwtDecode(token)
  const { role } = user

  if (logged) {
    Impact.setAuthToken(token)
    try {
      const resp = await Impact.refreshToken(role)
      await dispatch(refreshUser(resp, role))
    } catch (error) {
      dispatch(logout())
      throwError(error)
    }
  } else {
    dispatch(logout())
  }
}

export const updateUser = ({ avatar, ...user }, id, role) => async dispatch => {
  try {
    const newUser = user
    if (avatar) {
      newUser.avatar = avatar.signed_id
    } else {
      newUser.avatar = null
    }

    const updateRequest =
      role === TRAINER ? Impact.updateTrainer : Impact.updateClient

    const resp = await updateRequest({ [role]: { ...newUser } }, id)
    await dispatch(refreshUser(resp, role))
  } catch (error) {
    throwError(error)
  }
}

export const updateClientNotification = () => async dispatch => {
  const {
    data: { client_notification },
  } = await Impact.updateClientNotification()

  dispatch(setNotification(client_notification))
}
