import Impact from '~/services/Impact'
import { createApiAction, throwError, createAction } from '~/state/utils'
import { DeepDeserializer } from '~/services/Deserializer'

export const FETCH = createApiAction('client_nutrition / FETCH_DATA')

export const getClientPrograms = () =>
  Impact.thunk(FETCH, async () => {
    const { client_programs } = await Impact.getClientProgram()
    const data = await new DeepDeserializer().deserialize(client_programs)

    return { data }
  })

export const getClientNutrition = () =>
  Impact.thunk(FETCH, async () => {
    const { nutritions } = await Impact.getClientNutrition()
    const data = await new DeepDeserializer().deserialize(nutritions)

    return { data }
  })

export const getClientNutritionByProgramId = program_id =>
  Impact.thunk(FETCH, async () => {
    const { nutritions } = await Impact.getClientNutritionByProgramId(
      program_id
    )
    const data = await new DeepDeserializer().deserialize(nutritions)

    return { data }
  })

export const updateFollowNutrition = (answer, data) => async dispatch => {
  try {
    const itemData = { ...data }

    if (!itemData.followed_nutrition) {
      itemData.followed_nutrition = {}
    }

    const client_program = {
      followed_nutrition: {
        ...itemData.followed_nutrition,
        ...answer,
      },
    }

    const {
      data: { program },
    } = await Impact.updateClientNutrition(itemData.id, {
      client_program,
    })
    const payload = await new DeepDeserializer().deserialize(program)

    await dispatch(createAction(FETCH.SUCCESS)(payload))
  } catch (err) {
    throwError(err)
  }
}
