import { Deserializer } from 'jsonapi-serializer'

import { toastr } from 'react-redux-toastr'
import Impact from '~/services/Impact'
import { createApiAction, createAction, throwError } from '~/state/utils'
import { deleteFolderItem } from '~/state/modules/folders/actions'

export const FETCH_NUTRITION = createApiAction('nutrition / FETCH_DATA')
export const FETCH_NUTRITION_BY_ID = createApiAction(
  'nutrition / FETCH_NUTRITION_BY_ID'
)
export const CREATE_NUTRITION = 'nutrition / CREATE_NUTRITION'
export const UPDATE_NUTRITION = 'nutrition / UPDATE_NUTRITION'
export const GET_FOLDER_NUTRITION = 'nutrition / GET_FOLDER_NUTRITION'

export const createNutritionAction = createAction(CREATE_NUTRITION)
export const updateNutritionData = createAction(UPDATE_NUTRITION)

export const getAllNutrition = () =>
  Impact.thunk(FETCH_NUTRITION, async () => {
    const { nutritions } = await Impact.getAllNutrition()
    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(nutritions)

    return { data }
  })

export const createNutrition = name => async dispatch => {
  try {
    const {
      data: { nutrition, message },
    } = await Impact.createNutrition(name)

    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(nutrition)

    await dispatch(createNutritionAction(payload, message))

    return payload
  } catch (error) {
    throwError(error)
  }
  return {}
}

export const deleteNutrition = ({ id, folderType }) => async dispatch => {
  try {
    const {
      data: { message },
    } = await Impact.deleteNutrition(id)

    await dispatch(deleteFolderItem({ id, folderType }, message))
  } catch (error) {
    throwError(error)
  }
}

export const getNutritionById = id =>
  Impact.thunk(FETCH_NUTRITION_BY_ID, async () => {
    const { nutrition } = await Impact.getNutritionById(id)

    const data = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(nutrition)

    return { data }
  })

export const updateNutrition = ({ id, ...data }) => async dispatch => {
  try {
    const {
      data: { nutrition, message },
    } = await Impact.updateNutrition(id, data)

    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(nutrition)

    await dispatch(updateNutritionData(payload, message))
  } catch (error) {
    toastr.error('Error', error.response.data.errors)
    throw Error(error)
  }
}

export const cloneNutrition = values => async dispatch => {
  try {
    const {
      data: { nutrition },
    } = await Impact.cloneNutrition(values)

    const payload = await new Deserializer({
      keyForAttribute: 'snake_case',
    }).deserialize(nutrition)

    await dispatch(createNutritionAction(payload, ''))
    return payload
  } catch (error) {
    throwError(error)
  }
  return {}
}
